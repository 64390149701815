<template>
  <div class="account-my-info-page">
    <account-main-info
      v-if="accountMainInfo"
      :account-main-info="accountMainInfo"
    />

    <account-additional-info
      v-if="accountAdditionalInfo"
      :account-additional-info="accountAdditionalInfo"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import pick from 'lodash/pick';
import AccountMainInfo from './AccountMainInfo.vue';
import AccountAdditionalInfo from './AccountAdditionalInfo.vue';

export default {
  name: 'AccountMyInfoPage',

  components: { AccountAdditionalInfo, AccountMainInfo },

  // TODO: удалить provide/inject, когда на беке появится возможность редактирования
  provide: {
    dataIsEditable: false,
  },

  computed: {
    ...mapState('user', ['loggedInUser']),

    accountMainInfo() {
      return this.loggedInUser
        ? pick(this.loggedInUser, ['appointmentName', 'corpStructure', 'fullName', 'photoURL'])
        : null;
    },

    accountAdditionalInfo() {
      return this.loggedInUser
        ? pick(this.loggedInUser, ['employeeNumber', 'email', 'location', 'managerFullName', 'mobilePhone'])
        : null;
    },
  },
};
</script>
