<template>
  <div class="account-additional-info block block--rounded block--white">
    <div class="account-additional-info__block">
      <h2>Персональная информация</h2>

      <table class="typo-body-m">
        <tr v-if="accountAdditionalInfo.employeeNumber">
          <td>Табельный номер</td>
          <td>{{ accountAdditionalInfo.employeeNumber }}</td>
        </tr>

        <tr v-if="accountAdditionalInfo.managerFullName">
          <td>Руководитель</td>
          <td>{{ accountAdditionalInfo.managerFullName }}</td>
        </tr>

        <tr v-if="accountAdditionalInfo.location">
          <td>Адрес офиса</td>
          <td>{{ accountAdditionalInfo.location }}</td>
        </tr>
      </table>
    </div>

    <div class="account-additional-info__block">
      <h2>Контактная информация</h2>

      <table class="typo-body-m">
        <tr v-if="accountAdditionalInfo.email">
          <td>Корпоративная почта</td>
          <td>{{ accountAdditionalInfo.email }}</td>
        </tr>

        <tr v-if="accountAdditionalInfo.mobilePhone">
          <td>Рабочий телефон</td>
          <td>{{ accountAdditionalInfo.mobilePhone }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountAdditionalInfo',

  props: {
    accountAdditionalInfo: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.account-additional-info {
  padding: 38px 32px 36px;
  display: flex;

  @include for-size(tablet-landscape-down) {
    flex-direction: column;
  }

  @include for-size(phone-portrait-down) {
    width: 100vw;
    margin-left: -20px;
    padding: 20px;
  }

  &__block {
    flex-grow: 1;
    padding-right: 32px;

    &:not(:last-child) {
      @include for-size(tablet-landscape-down) {
        margin-bottom: 50px;
      }
      @include for-size(phone-portrait-down) {
        margin-bottom: 32px;
      }
    }

    @include for-size(tablet-landscape-down) {
      padding-right: 0;
    }

    h2 {
      margin-bottom: 26px;
    }

    table {
      tr {
        &:not(:last-child) {
          td {
            padding-bottom: 24px;
            @include for-size(phone-portrait-down) {
              padding-bottom: 20px;
            }
          }
        }

        td {
          &:first-child {
            color: $color-grayscale-60;
            padding-right: 40px;
            @include for-size(phone-portrait-down) {
              padding-right: 20px;
            }
          }
          @include for-size(phone-portrait-down) {
            font-size: 13px;
            line-height: 20px;
          }
        }
      }
    }
  }
}
</style>
